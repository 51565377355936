import React from 'react'
import { Link } from "gatsby"
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Helmet from 'react-helmet'
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap'
import styled from 'styled-components'
import Scroll from 'react-scroll'
import Accordion from '../../components/Accordion'
import ContactForm from '../../components/ContactForm'
import HiringSection from '../../components/Hiring'
import Layout from '../../components/Layout'

import implementationProcess from '../../img/pages/about-us/implementation-payment-process.png'

const WorksTable = styled.div`
  font-size: 1.1rem; line-height: 1.8rem; font-weight: 300; text-align:center; width: 100%;
  @media(max-width: 360px) {
    display: none;
  }
`
const WorksTableHeader = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  @media(max-width: 650px) {
    font-size: 1rem;
  }
`
const WorksTableHeaderCell = styled.div`
  &:nth-child(3n+2) {
    border-bottom: 5px solid #88c5cc;
  }
  &:nth-child(3n+3) {
    border-bottom: 5px solid #ffc107;
  }
  width: 40%;
  padding: 8px 15px;
  text-align: center;
  box-sizing: border-box;
  @media(max-width: 650px) {
    width: 35%;
  }
  &:first-child {
    width: 20%;
    @media(max-width: 650px) {
      width: 30%;
    }
  }
`
const WorksTableBodyRow = styled.div`
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
`
const WorksTableBodyCell = styled.div`
  border-right: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  padding: 12px 15px;
  text-align: center;
  box-sizing: border-box;
  width: 40%;
  &:first-child {
    font-size: 1.1rem;
    min-height: 53px;
    font-weight: 400;
    border-bottom: none;
    justify-content: flex-end;
    width: 20%;
    @media(max-width: 650px) {
      font-size: .9rem;
      width: 30%;
    }
  }
  &:not(:first-child) {
    background-color:#fff;
    @media(max-width: 650px) {
      font-size: .9rem;
      width: 35%;
    }
  }
  ${WorksTableBodyRow}:nth-child(2n+0) &:first-child {
    border-right: 5px solid #ffc107;
  }
  ${WorksTableBodyRow}:nth-child(2n+1) &:first-child {
    border-right: 5px solid #88c5cc;
  }
`
const ModelHeader = styled.h3`
  color: #5b5b5b;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2rem;
  text-align: center;
  padding-bottom: 2.5rem;
  position: relative;
  &:after {
    background: ${props => props.background};
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    bottom: 12px;
    left: 50%;
    margin-left: -5px;
  }
`
const ModelBody = styled.p`
  padding-bottom: 5.5rem;
  position: relative;
  &:after {
    background: ${props => props.background};
    content: "";
    display: block;
    width: 3px;
    height: 55px;
    position: absolute;
    bottom: 12px;
    left: 50%;
    margin-left: -1.5px;
  }
`
const OrangeDotLI = styled.li`
  padding: 0 0 0 30px;
  margin: 0;
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 9px;
    left: 0;
    background: #ffc107;
  }
`

export default class extends React.Component {

  render() {
    return (
      <Layout>
    <div className="about-us">
      <Helmet
        title="How We Work | Bishop Software"
        meta={[
          { name: 'description', content: 'Bishop Software offers custom software development, web design, mobile development, software consulting, and user experience services to businesses like yours. Let\'s talk.' },
          { name: 'keywords', content: 'software,development,web,design,mobile,consultancy,consulting,website,application,react,ios,android' }
        ]}
      />
      <section style={{paddingTop: '75px', display: 'block', backgroundImage: 'linear-gradient(132deg, rgba(3,57,115,1) 0%, rgba(44,136,148,1) 73%, rgba(26,174,196,1) 90%, rgba(33,225,255,1) 100%)', color: '#fafafa'}}>
        <Header dropdownGradient='linear-gradient(#24a4bb 0px, rgb(20, 80, 142) 100%)' section="about-us" />
        <Container className="text-center" style={{paddingTop: '1.875rem'}}>
          <div style={{margin: '55px 0 0 0'}}>
            <h1 style={{color: '#ffc107', fontSize: '40px', fontWeight: 300}}>
              How We Work
            </h1>
            <h3 style={{color: '#ddd', margin: '35px 0'}}>Bishop Software works under the two contractual models:</h3>
            <div style={{fontSize: '20px'}}>
              <div style={{display: 'inline-block'}}>
                <i className="fas fa-check" style={{color: '#ffc107'}} /> &nbsp; Time &amp; Materials
              </div>
              <div style={{display: 'inline-block', marginLeft: '15%'}}>
                <i className="fas fa-check" style={{color: '#ffc107'}} /> &nbsp; Fixed Price
              </div>
            </div>
            <div style={{marginTop: '35px'}}>
            Сompare your project needs with the models described below to select the most appropriate contract type. We work using both Agile and non-Agile methodologies, and can combine them to get the most out of each methodology to the benefit of your project. We work in full compliance with ISO 27001 and ISO 9001 standards.
            </div>
            <div style={{marginTop: '35px', paddingBottom: '35px'}}>
              <Scroll.Link to="contact" spy={true} smooth={true} className="round btn btn-primary"><i className="fas fa-arrow-down"></i></Scroll.Link>
            </div>

            
          </div>
        </Container>
      </section>

    <Scroll.Element name="contact" />
  <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(176deg, #fff 0, #efefef 90%, #eee 100%)'}}>
    <Container>
      <Row>
        <Col>
          <WorksTable>
            <WorksTableHeader>
              <WorksTableHeaderCell>&nbsp;</WorksTableHeaderCell>
              <WorksTableHeaderCell>Time &amp; Materials</WorksTableHeaderCell>
              <WorksTableHeaderCell>Fixed Price</WorksTableHeaderCell>
            </WorksTableHeader>
            <WorksTableBodyRow>
              <WorksTableBodyCell>Project size</WorksTableBodyCell>
              <WorksTableBodyCell>Medium and large</WorksTableBodyCell>
              <WorksTableBodyCell>Small and medium, or part of any size project (sprint, milestone, project stage)</WorksTableBodyCell>
            </WorksTableBodyRow>
            <WorksTableBodyRow>
              <WorksTableBodyCell>Project duration</WorksTableBodyCell>
              <WorksTableBodyCell>From 1 month to any</WorksTableBodyCell>
              <WorksTableBodyCell>From 1 day to 4 months</WorksTableBodyCell>
            </WorksTableBodyRow>
            <WorksTableBodyRow>
              <WorksTableBodyCell>Requirements for project start</WorksTableBodyCell>
              <WorksTableBodyCell>High-level requirements</WorksTableBodyCell>
              <WorksTableBodyCell>Detailed, fixed</WorksTableBodyCell>
            </WorksTableBodyRow>
            <WorksTableBodyRow>
              <WorksTableBodyCell>Project scope</WorksTableBodyCell>
              <WorksTableBodyCell>Implementation during the project process</WorksTableBodyCell>
              <WorksTableBodyCell>Completion before the project start</WorksTableBodyCell>
            </WorksTableBodyRow>
            <WorksTableBodyRow>
              <WorksTableBodyCell>Change requests</WorksTableBodyCell>
              <WorksTableBodyCell>Possible during the project implementation</WorksTableBodyCell>
              <WorksTableBodyCell>Possible after the project completion / within next product version</WorksTableBodyCell>
            </WorksTableBodyRow>
            <WorksTableBodyRow>
              <WorksTableBodyCell>Project start</WorksTableBodyCell>
              <WorksTableBodyCell>After the agreement signing</WorksTableBodyCell>
              <WorksTableBodyCell>After project scope approved</WorksTableBodyCell>
            </WorksTableBodyRow>
            <WorksTableBodyRow>
              <WorksTableBodyCell>Client control of the progress</WorksTableBodyCell>
              <WorksTableBodyCell>Full control of the everyday progress</WorksTableBodyCell>
              <WorksTableBodyCell>Low control of the process, only the results of the sprint / milestone / project stage</WorksTableBodyCell>
            </WorksTableBodyRow>
            <WorksTableBodyRow>
              <WorksTableBodyCell>Project cost</WorksTableBodyCell>
              <WorksTableBodyCell>Based on the time spent, does not include the risks</WorksTableBodyCell>
              <WorksTableBodyCell>Based on the predictable time spent+risks</WorksTableBodyCell>
            </WorksTableBodyRow>
            <WorksTableBodyRow>
              <WorksTableBodyCell>Reports</WorksTableBodyCell>
              <WorksTableBodyCell>Daily reports</WorksTableBodyCell>
              <WorksTableBodyCell>None</WorksTableBodyCell>
            </WorksTableBodyRow>
            <WorksTableBodyRow>
              <WorksTableBodyCell>Methodology</WorksTableBodyCell>
              <WorksTableBodyCell>Agile (Scrum, Kanban, Scrumban)</WorksTableBodyCell>
              <WorksTableBodyCell>Agile (Scrum, Kanban, Scrumban) and non-Agile (Waterfall)</WorksTableBodyCell>
            </WorksTableBodyRow>
          </WorksTable>

          <div style={{textAlign: 'center', marginTop: '35px'}}>
          If you'd like us to help you with the selection of the most appropriate contractual model and/or discuss your project, feel free to contact our customer care management team.<br /><br />
          <Link to="/contact" className="btn btn-primary round">Contact Us</Link>
          </div>
        </Col>
      </Row>
    </Container>
  </section>

  <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(176deg, #fff 0, #efefef 90%, #eee 100%)'}}>
    <Container>
      <Row style={{maxWidth: '790px', margin: '0 auto'}}>
        <Col>
        <h1 style={{color: '#666', marginBottom: '45px', textAlign: 'center', fontSize: '40px', fontWeight: 300}}>
          Time &amp; Materials
        </h1>
        <ModelHeader background="#88c5cc">What?</ModelHeader>
        <ModelBody background="#88c5cc">In this model you are charged for the total time spent by the project manager, developers and QA during the development stage according to the hourly rate.</ModelBody>
        <ModelHeader background="#88c5cc">When?</ModelHeader>
        <ModelBody background="#88c5cc">This model is suitable for open-ended and long-term/short-term projects that has no complete specification available at the initial stage and when amendments can be made to the specification during project development.<br /><br />
Other cases when this model is applicable: for startups with no or incomplete specification
For smooth start and further work on the project under Time &amp; Materials model writing detailed specification documents is inevitable. You’ll cooperate and communicate with our project managers/ business analysts who will at your disposal to discuss any project-related issues and timely suggest the right solution.</ModelBody>
        <ModelHeader background="#88c5cc">Why?</ModelHeader>
        <p>Time &amp; Materials contract makes the development process more transparent, cost-effective and manageable.<br /><br />
At the project discussion stage we agree upon the project scope and our project managers prepare a detailed specification. Preliminary estimation is provided with indication of project duration and approximate cost per month. After you approve the estimation we conclude a NDA that covers all aspects of confidentiality, licensing and maintenance. When the contract is signed and the first installment is received, our team gets down to work.<br /><br />
In accordance with the contract terms you are billed monthly. Progress reports are submitted to your on a daily basis. Timesheets and prices are provided monthly.</p>
        </Col>
      </Row>
    </Container>
  </section>

  <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(176deg, #fff 0, #efefef 90%, #eee 100%)'}}>
    <Container>
      <Row style={{maxWidth: '790px', margin: '0 auto'}}>
        <Col>
        <h1 style={{color: '#666', marginBottom: '45px', textAlign: 'center', fontSize: '40px', fontWeight: 300}}>
          Fixed Price
        </h1>
        <ModelHeader background="#ffc107">What?</ModelHeader>
        <ModelBody background="#ffc107">A contractual model that means that the project price is fixed at the outset for the known project scope and no actual work price will be taken into account.</ModelBody>
        <ModelHeader background="#ffc107">When?</ModelHeader>
        <ModelBody background="#ffc107">This model is suitable when you have a project with fixed requirements, the timelines are set and the budget is critical.</ModelBody>
        <ModelHeader background="#ffc107">Why?</ModelHeader>
        <p>It is considered the most cost-effective contractual model for small and middle-sized projects because you can budget for the costs and ensure there’s enough funds for project completion.</p>
        
        <h4 style={{marginTop: '55px'}}>There are several types of fixed price contracts we work under:</h4>
        <ul style={{listStyleType: 'none', margin: '1.7rem 0 0 0', padding: 0}}>
          <OrangeDotLI><strong>FPIF - fixed price сontract with incentive firm (firm target)</strong>. It means you pay only for the project scope, while the actual work is not considered. If the target goal is achieved at less costs than stipulated at the outset, it results in higher profit for us. If the target goal is achieved at higher costs, you save money on the project.</OrangeDotLI>
          <OrangeDotLI><strong>FFP - firm fixed price contract</strong>. It means the project price is fixed at the outset for the known project scope and can’t be changed during the project development.</OrangeDotLI>
          <OrangeDotLI><strong>FP-EPA - fixed price contract with economic price adjustment</strong>. It means the price for the long-term contract that can last several years can change due to the changing project conditions, such as costs increase.</OrangeDotLI>
        </ul>
        </Col>
      </Row>
    </Container>
  </section>

  <section style={{backgroundImage: 'linear-gradient(179deg, #2c2f39 2%, #363944 14%, #32353d 100%)', color: '#ccc', padding: '70px 0'}}>
    <Container>
      <Row style={{maxWidth: '850px', margin: '0 auto'}}>
        <Col>
          <h2 style={{color: '#fff', marginBottom: '35px'}} className="text-center">Why Choose Bishop Softrware</h2>
          <ul style={{margin: 0, padding: 0, listStyleType: 'none'}}>
            <OrangeDotLI style={{marginBottom: '10px'}}><strong style={{color: '#fff'}}>Experience</strong> &nbsp; (5+ years in IT Outsourcing)</OrangeDotLI>
            <OrangeDotLI style={{marginBottom: '10px'}}><strong style={{color: '#fff'}}>Scalability</strong> &nbsp; (we have resources to gradually grow your outsourcing team)</OrangeDotLI>
            <OrangeDotLI style={{marginBottom: '10px'}}><strong style={{color: '#fff'}}>Absolute workflow transparency</strong> &nbsp; (providing an insight into what each team member is occupied with any time)</OrangeDotLI>
            <OrangeDotLI style={{marginBottom: '10px'}}><strong style={{color: '#fff'}}>Flexibility</strong> &nbsp; (work with both startups and big enterprises)</OrangeDotLI>
            <OrangeDotLI style={{marginBottom: '10px'}}><strong style={{color: '#fff'}}>Always ready-to-help customer-centric staff</strong></OrangeDotLI>
          </ul>
        </Col>
      </Row>
    </Container>
  </section>

  <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(176deg, #ddd 0, #fff 30%, #fff 90%, #f1f1f1 100%)'}}>
      <ContactForm />
    </section>
        <HiringSection />

      <Footer section="about-us" />

    </div>
  </Layout>
    )
  }
}