import React, { Component } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col, Collapse } from "reactstrap";

const Head = styled.div`
  position: relative;
  padding: 0 20px;
  cursor: pointer;
  transition: background 0.15s ease-in-out;
`;
const Title = styled.div`
  color: #000;
  font-weight: 400;
  font-size: 2rem;
  padding: 20px 0 20px 60px;

  @media (max-width: 770px) {
    font-size: 1.5em;
  }
  @media (max-width: 480px) {
    font-size: 1em;
  }

`;
const Icon = styled.i`
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -23px;
  color: #1c838f;
`
const Toggle = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  color: #333;
`;
const EyeIcon = styled.i`
  @media(max-width:480px) {
    display:none !important;
  }
`
const Content = styled.div`
  padding: 27px 38px;background:#fefefe;
`


export default class extends Component {
  constructor() {
    super();
    this.state = {
      open: false
    };
  }

  click = () => {
    this.setState({
      open: !this.state.open
    })
  };

  getOpenIcon = () => this.state.open ? (
      <EyeIcon className="fas fa-arrow-circle-up fa-2x" />
    ) : (
      <EyeIcon className="fas fa-eye fa-2x" />
    )

  render() {
    return (
      <div style={{marginBottom: '40px'}}>
        <Head onClick={this.click} style={{background: this.state.open ? '#afafaf' : '#ccc'}}>
          <Icon className={this.props.icon} />
          <Title>
            {this.props.title}
          </Title>
          <Toggle>
            {this.getOpenIcon()}
          </Toggle>
        </Head>
        <Collapse isOpen={this.state.open}>
          <Content>
            {this.props.children}
          </Content>
        </Collapse>
      </div>
    );
  }
}
